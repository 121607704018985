<template>
  <section class="section-stories">
    <div class="col">
      <p v-html="cms.page.fgItsAJoyToShare"></p>
      <div class="buttons-container">
        <router-link :to="{ name: 'Prayer'}" class="btn btn-default">
          {{ cms.page.fgPray }}
        </router-link>
        <router-link :to="{ name: 'GivingPage'}" class="btn btn-alternate">
          {{ cms.page.fgGive }}
        </router-link>
      </div>
    </div>
    <div class="col">
      <div class="header">
        <span> </span>
      </div>
      <div class="image-container">
        <router-link :to="{ name: 'MinistryUpdate', params: { id: 'welcome' }}">
          <img
              :src="featuredArticle.image"
              class="img-fluid"
          />
        </router-link>
      </div>
      <h6 class="header-ribbon"></h6>
      <h4 class="featured-article-main-title">{{ featuredArticle.mainTitle }}</h4>
      <h4>{{ featuredArticle.subTitle }}</h4>
      <p>
        {{ featuredArticle.textPreview }}
      </p>

      <router-link
          :to="{ name: 'MinistryUpdate', params: { id: 'welcome' }}"
          class="btn btn-default"
      >
        {{ cms.page.fgReadWelcome }}
      </router-link>
    </div>
  </section>
  <div class="container-s wrapper-article">
    <!--    <div class="tag tag-col">-->
    <!--      <div class="tag-label">{{ cms.page.fgTags }}</div>-->
    <!--      <div class="tag-nav">-->
    <!--        <a-->
    <!--          >{{ tagItem.tag }}</a-->
    <!--        >-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="articles-container articles-container-col">
      <h2 class="title-underline">{{ cms.page.fgReadAboutChangedLives }}</h2>
      <!-- <hr/> -->
      <!--      <div class="tag-select-container">-->
      <!--        <Select-->
      <!--            :value="activeTag"-->
      <!--            :list="selectTagItems"-->
      <!--            block-->
      <!--            @select-item="(v) => selectTag(v.name)"-->
      <!--        >-->
      <!--          {{ cms.page.fgTags }}-->
      <!--        </Select>-->
      <!--      </div>-->

      <div v-if="cms.loadedMinistryUpdates" class="articles-list">
        <!--          :to="`/ministry-update/${item.id}`"-->
        <router-link
            v-for="item in displayArticles"
            :to="{ name: 'MinistryUpdate', params: { id: item.id }}"
            :key="item.id"
            class="article"
        >
          <div class="image-container">
            <img
                :src="item.imageThumb"
                class="img-fluid"
            />
          </div>
          <div class="text-container">
            <h4>{{ item.title }}</h4>
            <p>{{ item.textPreview }}</p>
          </div>
        </router-link>
      </div>
      <div class="buttons-container">
        <button
            class="btn btn-default"
            v-if="cmsStore.ministryUpdatesAreMoreAvailable"
            @click="increaseDisplayLimit"
        >
          {{ cms.page.fgLoadMore }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Select from "@/components/Select";
import {mapStores} from "pinia";
import {useDesignStore} from "@/stores/designStore";
import {useApiCallsStore} from "@/stores/apiCallsStore";
import {useCmsStore} from "@/stores/cmsStore";
import inView from "in-view";

const tagItems = [
  {slug: "all", tag: "All"},
  {slug: "featured", tag: "Featured"},
  {slug: "hotel_or_motel", tag: "Hotel/Motel"},
  {slug: "impact_story", tag: "Impact story"},
  {slug: "inspirational", tag: "Inspirational"},
  {slug: "member", tag: "Member"},
  {slug: "military", tag: "Military"},
  {slug: "ministry", tag: "Ministry"},
  {slug: "scripture_blitz", tag: "Scripture Blitz"},
  {slug: "why_i_am_member", tag: "Why I’m a Member"},
];

const articles = [
  {
    title: "Wisdom to Live and Give Well oscar",
    text: "A famous adage teaches that if you don’t stand for something, you will fall for anything. George Newfiel...",
    image: "1.jpg",
    tag: "Why I’m a Member",
    key: "WhyIAmMember",
  },
  {
    title: "God’s Work Continues in India and Australia",
    text: "More than 40% of the population is unevangelized, and the harvest field is ripe for believers. Gideons and...",
    image: "2.jpg",
    tag: "Ministry",
    key: "Ministry1",
  },
  {
    title: "The Gift that Keeps on Giving",
    text: "October is Pastor Appreciation Month, and The Gideons International loves to partner with and support local ...",
    image: "3.jpg",
    tag: "Military",
    key: "Military1",
  },
  {
    title: "Who are Gideons: Men of Faith",
    text: "Discover how membership in The Gideons helps Christian business and professional men grow as Men of Faith.",
    image: "4.jpg",
    tag: "Ministry",
    key: "Ministry2",
  },
  {
    title: "Who are Gideons: Men of Action",
    text: "Discover how membership in The Gideons helps Christian business and professional men grow as Men of Faith.",
    image: "5.jpg",
    tag: "Ministry",
    key: "Ministry3",
  },
  {
    title: "Who are Gideons: Men of God",
    text: "Discover how membership in The Gideons helps Christian business and professional men grow as Men of God…",
    image: "6.jpg",
    tag: "Ministry",
    key: "Ministry4",
  },
];

export default {
  name: "Ministry",
  components: {Select},
  computed: {
    ...mapStores(useApiCallsStore, useCmsStore, useDesignStore),
  },
  data() {
    return {
      cms: {
        loaded: false, // set to 'true' when CMS content has been returned from API
        loadedMinistryUpdates: false,
        options: {
          Common: true,
          Components: [],
          LanguageCode: "",
          PageName: "ministry",
          MinistryUpdates: true,
          PageSize: "13", // set to 13 originally, because of the 'Welcome' article that needs to be fetched only once
          Page: "0",
          Sorting: "dDate_Posted", // not a typo, the first 'd' means descending order
        },
        page: {},
      },
      displayArticles: {},
      displayLimit: 8,
      featuredArticle: {
        textPreview: "",
        date: "",
        id: "",
        image: "0.jpg",
        title: "",
      },
      updatesStatus: {
        countArticlesLoaded: 0,
        needToFetchMore: false,
        showLoadMoreButton: true, // displayLimit <= cmsStore.getMinistryUpdateCount
      },
      tagItems,
      activeTag: tagItems[0].tag,
      articles,
    };
  },
  async mounted() {
    this.resetMinistryUpdatesStatus();
    this.setTitlePageFromCms()
  },
  async created() {
    this.setContentLoaded(false);
    await this.fetchMinistryUpdates();
    window.addEventListener("scroll", this.handleScroll);
    await this.fetchCmsData();
  },
  methods: {
    assignFeaturedArticle() {
      const article = this.cmsStore.getFeaturedArticle;

      this.featuredArticle.textPreview = article.textPreview;
      this.featuredArticle.date = article.date;
      this.featuredArticle.id = article.id;
      this.featuredArticle.image = article.imageThumb;
      this.featuredArticle.mainTitle = article.mainTitle;
      this.featuredArticle.subTitle = article.subTitle;
      this.featuredArticle.title = article.title;
    },
    calculateMinistryUpdatesStatus() {
      this.updatesStatus.countArticlesLoaded =
          this.cmsStore.getMinistryUpdateCount;

      if (this.displayLimit >= this.updatesStatus.countArticlesLoaded)
        this.updatesStatus.needToFetchMore = true;

      let showLimit = this.displayLimit;
      this.cmsStore.ministryUpdates.forEach((article, key) => {
        if (showLimit < 1) return false;
        showLimit--;

        this.displayArticles[key] = article;
      });
    },
    async fetchCmsData() {
      try {
        await this.cmsStore
            .getPageAndOrCommonAndOrComponents(this.cms.options)
            .then((results) => {
              if (results) {
                this.cms.page = results;
              } else return false;

              this.setAllCmsContent(results);
              this.setContentLoaded(true);
              return true;
            });
      } catch (e) {
        console.error(e);
      }
    },
    async fetchMinistryUpdates() {
      try {
        await this.cmsStore
            .getMinistryUpdates(this.cms.options)
            .then((results) => {
              if (results) {
                this.cms.loadedMinistryUpdates = true;
              } else return false;

              this.assignFeaturedArticle();
              this.calculateMinistryUpdatesStatus();
              return true;
            });
      } catch (e) {
        console.error(e);
      }
    },
    increaseDisplayLimit() {
      this.cms.options.PageSize = "12" // reset to '12' because we want to fetch in batches of 12 items, not 13
      this.displayLimit = this.displayLimit + 4;

      this.calculateMinistryUpdatesStatus();
    },
    resetMinistryUpdatesStatus() {
      this.updatesStatus.countArticlesLoaded = 0;
      this.updatesStatus.needToFetchMore = false;
      this.updatesStatus.showLoadMoreButton = true;
    },
    selectTag(tag) {
      this.activeTag = tag;
    },
    setAllCmsComponentContent(componentContents) {
      this.cms.comp = componentContents;
    },
    setTitlePageFromCms(page) {
      const pageTitle = page && page.fgMinistryUpdates ? page.fgMinistryUpdates : 'Ministry Updates'
      const pageSubtitle = page && page.fgReadTheLatestMinistryUpdates ? page.fgReadTheLatestMinistryUpdates : 'Read the latest ministry updates'

      this.designStore.setTitlePage({
        title: pageTitle,
        sub_title: pageSubtitle,
        img_class: "bg-ministry",
        btn_history: false,
      });
    },
    setAllCmsContent(cmsContent) {
      this.cms.page = cmsContent;
      this.setTagItemsFromCms(cmsContent);
      this.setArticuleFromCms(cmsContent);
      this.setTitlePageFromCms(cmsContent);
    },
    setContentLoaded(loadedStatus) {
      this.cms.loaded = loadedStatus;
    },
    setTagItemsFromCms(cmsContent) {
      let tagItemNameFromSitecore = this.tagItems.map((item) => {
        if (item.slug.indexOf("_") > -1) {
          let itemArray = item.slug.split("_").map((sl) => {
            return sl.charAt(0).toUpperCase() + sl.slice(1);
          });
          return {
            key: `fgslug${itemArray.join("")}`,
            name: item.slug,
          };
        } else {
          return {
            key: `fgslug${
                item.slug.charAt(0).toUpperCase() + item.slug.slice(1)
            }`,
            name: item.slug,
          };
        }
      });
      this.tagItems = this.tagItems.map((item) => {
        let slugTag = tagItemNameFromSitecore.find((x) => x.name === item.slug);
        if (slugTag !== null) {
          return {
            slug: item.slug,
            tag: cmsContent[slugTag.key],
          };
        }
      });
    },
    setArticuleFromCms(cmsContent) {
      this.articles = this.articles.map((item) => {
        let text = `fgArticule${item.key}Text`;
        let title = `fgArticule${item.key}Title`;
        return {
          title: cmsContent[title],
          text: cmsContent[text],
          image: item.image,
          key: item.key,
          tag: item.tag,
        };
      });
    },
    handleScroll() {
      inView(".title-underline")
          .on("enter", (el) => {
            el.classList.add("inview");
          })
          .on("exit", (el) => {
            el.classList.remove("inview");
          });
    },
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  watch: {
    async "updatesStatus.needToFetchMore"(newVal) {
      if (newVal) {
        this.cms.options.Page += 1;
        this.updatesStatus.needToFetchMore = false;
        await this.fetchMinistryUpdates();
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/abstracts/_color.scss";

.section-stories,
.container-s {
  max-width: 2000px;
}

.wrapper-article {
  --horizontal-padding: 70px;

  @media screen and (max-width: $xl) {
    --horizontal-padding: 30px;
  }

  @media screen and (max-width: $lg) {
    --horizontal-padding: 10px;
  }
}

.section-stories {
  display: -ms-flexbox;
  display: flex;

  @media screen and (max-width: $lg) {
    -ms-flex-direction: column;
    flex-direction: column;
    padding-bottom: 40px;
  }

  .col {
    &:first-child {
      background-color: $white1;

      p {
        margin-bottom: 40px;
      }

      .buttons-container {
        .btn {
          margin-right: 70px;
          margin-bottom: 20px;

          &:last-child {
            margin-right: 0;
          }
        }

        .btn-alternate::before {
          background-color: #28677d;
        }
      }
    }

    &:last-child {
      background-color: $blue;
      color: white;

      .header {
        background-color: transparent;
        color: #0c2e3c;
        text-align: right;
        position: relative;
        height: 32px;
      }

      .image-container {
        position: relative;
      }

      h6 {
        margin-top: 12px;
      }

      h4 {
        text-transform: uppercase;
      }
    }

    @media screen and (max-width: $xl) {
      &:first-child,
      &:last-child {
        padding-left: var(--horizontal-padding);
      }

      &:last-child {
        .header {
          padding-right: var(--horizontal-padding);
        }
      }
    }

    @media screen and (max-width: $lg) {
      width: auto;

      &:first-child {
        padding-left: var(--horizontal-padding);
        padding-right: var(--horizontal-padding);

        .buttons-container {
          text-align: center;

          .btn {
            margin-left: 35px;
            margin-right: 35px;

            &:last-child {
              margin-right: 35px;
            }
          }
        }
      }

      &:last-child {
        padding-left: var(--horizontal-padding);
        padding-right: var(--horizontal-padding);
        margin: 0 10px;
        text-align: center;
        overflow-y: visible;

        .header {
          background-color: transparent;
          color: white;
          text-align: center;
          margin-left: 0;
          margin-bottom: 70px;
          font-size: 25px;

          &::before {
            display: none;
          }

          span {
            padding-bottom: 10px;
            border-bottom: 7px $yellow-darker solid;
          }
        }

        .image-container {
          overflow: visible;
          max-width: 100%;

          .img-fluid {
            margin-top: 0;
            width: 100%;
          }
        }

        .btn {
          transform: translateY(50px);
        }

        @media screen and (max-width: $sm) {
          .btn {
            transform: translateY(0);
          }
        }
      }
    }

    @media screen and (max-width: $sm) {
      &:last-child {
        .image-container {
          .img-fluid {
            transform: scale(1.2);
          }
        }
      }
    }
  }

  @media screen and (max-width: $lg) {
    overflow-x: hidden;
  }
}

.wrapper-title + .section {
  .section-stories {
    width: 1400px;
    margin: 0 auto;
    max-width: 100%;
    padding: 25px 15px 50px;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    z-index: 1;
    @include MQ(M) {
      padding: 25px 45px 50px;
    }

    .col {
      flex-basis: calc(50% - 50px);

      &:nth-child(1) {
        padding-top: 5px;
        margin-bottom: 35px;
        @include MQ(L) {
          margin-bottom: 0;
        }
      }

      &:nth-child(2) {
        position: relative;
        padding: 25px;
        @include MQ(M) {
        }
        @include MQ(L) {
          background: transparent;
          padding: 0 25px;
        }

        &:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0%;
          width: 200%;
          height: calc(100% + 50px);
          background: $blue;
          z-index: -1;
          display: none;
          @include MQ(L) {
            transform: translateY(-50%);
            display: block;
          }
        }

        .header {
          @include MQ(L) {
            left: -35px;
            padding: 6px 15px 6px 400px;
            text-align: left;
            font-weight: 500;
            max-width: 550px;
          }
        }

        .image-container {
          position: relative;
          width: calc(100% + 100px);
          max-width: none;
          left: -50px;
          margin-bottom: 10px;

          @media screen and (max-width: $sm) {
            margin: 0 auto 30px auto;
            width: 75%;
            left: 0;
          }

          img {
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
          }

          @include MQ(L) {
            margin-bottom: 0;
            max-width: 100%;
            width: 100%;
            left: inherit;
            position: absolute;
            bottom: calc(100% - 115px);
            a {
              width: 200px;
              height: 200px;
              display: block;

              img {
                object-fit: cover;
                width: 100%;
                height: 100%;
                @include MQ(M) {
                }
              }
            }
          }
        }

        h6 {
          margin-top: 0;
          font-weight: 400;
          margin-bottom: 0;
          @include MQ(L) {
            margin-top: 90px;
          }
        }
      }
    }
  }
}

.wrapper-article {
  display: flex;
  margin-top: 40px;
  margin-bottom: 80px;

  @media screen and (max-width: $lg) {
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 50px;
  }
}

.tag {
  @media screen and (max-width: $lg) {
    display: none;
  }

  &-label {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 30px;
    padding-left: var(--horizontal-padding);
  }

  &-col {
    width: 33.33333%;
  }

  &-nav {
    color: #0c2e3c;

    &-link {
      position: relative;
      font-size: 18px;
      line-height: 52px;
      text-transform: uppercase;
      display: block;
      color: $tag;
      transition: color 0.25s;
      padding-left: var(--horizontal-padding);

      &.active {
        color: #0c2e3c;
        font-weight: bold;

        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 10px;
          background-color: $blue-darker;
        }
      }

      &:before {
        width: 10px;
        height: 52px;
        background: transparent;
        display: block;
        content: "";
        position: absolute;
        left: 0;
        transition: background 0.25s;
      }

      &:hover {
        color: $tag-hover;

        &:before {
          background: $blue-darker;
        }
      }
    }
  }
}

.articles-container {
  &-col {
    width: 100%;

    > h2 {
      display: flex;
      justify-content: center;
      margin-top: 30px;
    }

    .tag-select-container {
      display: none;
      margin-bottom: 50px;

      .select-container {
        :deep .title {
          text-align: left;
        }
      }
    }

    .articles-list {
      --column-gap: 20px;
      display: -ms-flexbox;
      display: flex;
      justify-content: center;
      -ms-flex-align: stretch;
      align-items: stretch;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      column-gap: var(--column-gap);

      .article {
        width: calc(22% - var(--column-gap) * 2 / 3);
        margin-bottom: 60px;
        border-radius: 4px;
        box-shadow: 0 25px 25px -14px rgba(0, 0, 0, 0.5);
        color: $blue-darker;
        transition: all 0.33s ease;
        @media screen and (max-width: $xl) {
          width: calc(33% - var(--column-gap) * 2 / 3);
        }

        .image-container {
          position: relative;
          height: 200px;
          overflow: hidden;

          img {
            transition: all 0.33s ease;
            object-fit: cover;
            object-position: center;
            height: 100%;
            width: 100%;
          }

          .content {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-image: linear-gradient(
                    to bottom,
                    rgba(112, 112, 112, 0.5),
                    10%,
                    transparent
            );
            text-align: right;

            .tag {
              display: inline-block;
              margin-top: 20px;
              padding: 10px 15px;
              background: $blue;
              text-transform: uppercase;
              font-size: 10px;
              color: white;
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;
            }
          }
        }

        .text-container {
          padding: 30px 28px 0;

          h4 {
            text-transform: uppercase;
            font-weight: 500;
            line-height: 1.6em;
          }

          p {
            color: #707070;
            font-size: 14px;
            line-height: 1.9em;
            margin: 20px 0px;
          }
        }

        &:hover {
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

          .image-container {
            img {
              width: 105%;
              height: 105%;
            }
          }
        }
      }
    }

    .buttons-container {
      text-align: center;
    }

    @media screen and (max-width: $lg) {
      width: auto;
      padding-right: 0;
      text-align: center;

      > h2 {
        font-size: 25px;
      }

      > hr {
        margin: 15px 0 40px;
      }

      .tag-select-container {
        display: block;
      }

      .articles-list {
        -ms-flex-direction: column;
        flex-direction: column;

        .article {
          width: auto;

          .image-container {
            .img-fluid {
              width: 100%;
            }

            .content {
              text-align: center;

              .tag {
                margin-top: 53%;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
              }
            }
          }

          .text-container {
            padding-bottom: 6vw;
          }
        }
      }
    }

    @media screen and (max-width: $md) {
      .articles-list {
        .article {
          .image-container {
            .content {
              .tag {
                margin-top: 52.5%;
              }
            }
          }
        }
      }
    }

    @media screen and (max-width: $sm) {
      .articles-list {
        .article {
          .image-container {
            .content {
              .tag {
                margin-top: 51%;
              }
            }
          }
        }
      }
    }
  }
}

.header-ribbon {
  height: 32px;
}

.featured-article-main-title {
  margin-bottom: 0;
}

p :deep em {
  font-style: italic !important;
}
</style>
